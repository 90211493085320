import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './LoginPage.css';

const kakaoRedirectUrl = process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URL;
const googleRedirectUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;

const LoginPage = () => {
  const location = useLocation();

  useEffect(() => {
    // 이전 페이지 URL을 세션 스토리지에 저장
    const prevPath = location.state?.from || '/';
    console.log(location);
    sessionStorage.setItem('prevPath', prevPath);
  }, [location]);

  const handleKakaoLogin = () => {
    // 카카오 로그인 로직 구현
    console.log('카카오 로그인 시도');
    let KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=4613213528cd8cf322cfde00aec5f5a7&redirect_uri=${kakaoRedirectUrl}`;
    window.location.href = KAKAO_AUTH_URL;
  };

  const handleGoogleLogin = () => {
    // 구글 로그인 로직 구현
    let GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&scope=email&client_id=124683289694-vft35jh1vs141at2hoeqvd43t2elul72.apps.googleusercontent.com&redirect_uri=${googleRedirectUrl}`;
    window.location.href = GOOGLE_AUTH_URL;
    console.log('구글 로그인 시도');
  };

  return (
    <div className="login-container">
      <div className="frame-3421">
        <div className="frame-3459">
          <div className="frame-3324">
            <img className="logo-1" src="/img/logo.svg" alt="Logo" />
            <img className="logo-2" src="/img/logo-1.svg" alt="Logo" />
          </div>
          <h3 className="text-1-1 h3">코드지니 로그인</h3>
          <p className="ai s1normal">AI 코치와 함께 알고리즘 실력을 키워보세요.</p>
        </div>
        <div className="frame-3460">
          <article className="button" onClick={handleGoogleLogin}>
            <img className="logo" src="/img/logo-google.svg" alt="logo:google" />
            <h5 className="button-1 h5">구글로 시작하기</h5>
          </article>
          <article className="button-2" onClick={handleKakaoLogin}>
            <img className="logo" src="/img/logo-kakao.svg" alt="logo:kakao" />
            <h5 className="button-3 h5">카카오로 시작하기</h5>
          </article>
        </div>
        <div className="frame-3461 s1normal">
          <div className="text text-2">이용 약관</div>
          <div className="text-1 text-2">|</div>
          <div className="text text-2">개인정보 처리방침</div>
          <div className="text-1 text-2">|</div>
          <div className="text text-2">고객 지원</div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;