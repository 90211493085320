import "./FeedbackReports.css";

function FeedbackReports() {
  //TO-DO: Implement feedback reports page
  return (
    <div className="feedback-reports">
    </div>
  );
}

export default FeedbackReports;