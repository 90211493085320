import { useLocation, Link } from 'react-router-dom';
import "./Options.css";

const SOLVED_PROBLEM_PAGE = 'solved-problems';
const FEEDBACK_REPORT_PAGE = 'feedback-report';
const SETTINGS_PAGE = 'settings';

function Options() {
  const location = useLocation();
  const isSolvedProblemsPage = location.pathname.endsWith(SOLVED_PROBLEM_PAGE);
  const isFeedbackReportPage = location.pathname.endsWith(FEEDBACK_REPORT_PAGE);
  const isSettingsPage = location.pathname.endsWith(SETTINGS_PAGE);

  return (
    <div className="option-layout">
      <div className="option-container">
        <div className="category-text s2bold">활동 기록</div>
        <div className="option-group">
          <Link to={SOLVED_PROBLEM_PAGE} className="option">
            {isSolvedProblemsPage ? (
              <div className="selected-text h6">내가 푼 문제</div>
            ) : (
              <div className="unselected-text s1normal">내가 푼 문제</div>
            )}
          </Link>
          <Link to={FEEDBACK_REPORT_PAGE} className="option">
            {isFeedbackReportPage ? (
              <div className="selected-text h6">피드백 리포트</div>
            ) : (
              <div className="unselected-text s1normal">피드백 리포트</div>
            )}
          </Link>
        </div>
      </div>
      <div className="option-container">
        <div className="category-text s2bold">내 정보</div>
        <div className="option-group">
          <Link to={SETTINGS_PAGE} className="option">
            {isSettingsPage ? (
              <div className="selected-text h6">계정 관리</div>
            ) : (
              <div className="unselected-text s1normal">계정 관리</div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Options;