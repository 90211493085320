import "./Settings.css";

function Settings() {
    //TO-DO: Implement settings page
    return (
        <div className="settings">
        </div>
    );
}

export default Settings;