import { useLocation, Outlet } from 'react-router-dom';
import Header from './header/Header';
import SolvingHeader from './header/SolvingHeader';

function Layout({ children }) {
  const location = useLocation();
  const isSolvingPage = location.pathname.startsWith('/problem/solving/');

  return (
    <>
      {isSolvingPage ? <SolvingHeader /> : <Header />}
      <main><Outlet /></main>
    </>
  );
}

export default Layout;