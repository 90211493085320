import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UserProfile.css";

const apiUrl = process.env.REACT_APP_API_URL;

function UserProfile() {

  const memberId = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const [userNickname, setUserNickname] = useState("");
  const [userSolvedInfo, setUserSolvedInfo] = useState({
    triedProblemCount: 0,
    solvedProblemCount: 0,
    correctRate: 0.0,
    feedbackNoteCount: 0
  });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  //TO-DO: Implement user profile page and api call
  const fetchUserProfile = async () => {
    try {
      const response = await axios.post(`${apiUrl}/member/mypage/${memberId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUserNickname(response.data.data.memberInfo.nickname);
      setUserSolvedInfo(response.data.data.solvedInfo);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }

  return (
    <div className="profile-layout">
      <div className="profile">
        <div className="profile-image">
          <div className="shape"/>
        </div>
        <div className="profile-name">
          <h5 className="name-text h4">{userNickname}</h5>
        </div>
      </div>
      <div className="info-row">
        <div className="info">
          <div className="label s2bold">도전한 문제</div>
          <div className="number-value h4">{userSolvedInfo["triedProblemCount"]}</div>
        </div>
        <div className="info">
          <div className="label s2bold">맞춘 문제</div>
          <div className="number-value h4">{userSolvedInfo["solvedProblemCount"]}</div>
        </div>
      </div>
      <div className="info-row">
        <div className="info">
          <div className="label s2bold">정답률</div>
          <div className="percent-value h4">{(userSolvedInfo["correctRate"] * 100).toFixed(2)}%</div>
        </div>
        <div className="info">
          <div className="label s2bold">피드백 리포트 개수</div>
          <div className="number-value h4">{userSolvedInfo["feedbackNoteCount"]}</div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;