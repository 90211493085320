import React, { useState, useEffect } from 'react';
import { Link, useParams, Route, Routes } from 'react-router-dom';
import axios, { all } from 'axios';
import './SolvedProblems.css';

const apiUrl = process.env.REACT_APP_API_URL;

const SolvedProblems = () => {
  const memberId = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const [solvedProblems, setSolvedProblems] = useState(null);
  const [filters, setFilters] = useState({
    status: 'all',
    level: 0,
  });

  useEffect(() => {
    fetchSolvedProblems();
  }, [filters]);

  const fetchSolvedProblems = async () => {
    try {
      const response = await axios.post(`${apiUrl}/member/mypage/solved/${memberId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSolvedProblems(response.data.data.solvedProblemRecords);
    } catch (error) {
      console.error('해결한 문제 목록을 가져오는데 실패했습니다 :', error);
    }
  };

  const statusMap = [
    {key: "all", label: "전체"},
    {key: "right", label: "정답"},
    {key: "wrong", label: "오답"}
  ];

  const handleStatusChange = (status) => {
    setFilters(prev => ({
      ...prev,
      status: status
    }));
  };

  const allLevels = [0, 1, 2, 3, 4, 5];
  const handleLevelChange = (level) => {
    setFilters(prev => ({
      ...prev,
      level: level
    }));
  };

  return (
    <div className="solved-problems">

      <div className="solved-problems-header">
        <div className="title h4">내가 푼 문제</div>
        <div className="filter-container">

          <div className="filter">
            <div className="category-text s2normal">상태</div>
            <div className="option-group">
              {statusMap.map((state) => {
                const isSelected = (filters.status === state.key);
                const optionClass = isSelected ? "selected-option" : "unselected-option";
                const optionText = state.label;

                return (
                  <div className={`${optionClass}`} onClick={() => handleStatusChange(state.key)}>
                    <div className={`${optionClass}-text s2bold`}>{optionText}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="filter">
            <div className="category-text s2normal">난이도</div>
            <div className="option-group">
              {allLevels.map((level) => {
                const isSelected = (filters.level === level);
                const optionClass = isSelected ? "selected-option" : "unselected-option";
                const optionText = level === 0 ? "전체" : "Lv." + level;

                return (
                  <div className={`${optionClass}`} onClick={() => handleLevelChange(level)}>
                    <div className={`${optionClass}-text s2bold`}>{optionText}</div>
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      </div>

      <ul className="solved-problem-table">
        <div className="problem-column-detail s2normal">
          <div className='status status-text'>상태</div>
          <div className='problem-title title-text'>문제 제목</div>
          <div className='level level-text'>난이도</div>
          <div className='correct-rate correct-rate-text'>정답 비율</div>
        </div>
        
        {solvedProblems ? solvedProblems.map((problem) => {
          const solvedKey = filters.status === 'all' ? 'all' : problem.solved ? 'right' : 'wrong';
          if (filters.status !== solvedKey) return null;
          if (filters.level !== 0 && problem.level !== filters.level) return null;

          return <li className="problem-list">
            <div className="status">
              <div className={problem.solved ? "status-right-icon" : "status-wrong-icon"} />
            </div>
            <div className="problem-title title-text pnormal">
              <Link to={`/problem/solving/${problem.problemId}`}>{problem.title}</Link>
            </div>
            <div className="level">
              <div className={`level${problem.level} level-icon`}>
                <div className="level-text s2bold">Lv.{problem.level}</div>
              </div>
            </div>
            <div className="correct-rate correct-rate-text pnormal">{Math.floor(problem.correctRate * 100)}%</div>
          </li>
        }) : (
          <div style={{ height: '774px', width: '100%', display: 'flex' }}>
            <div className="lds-dual-ring"></div>
          </div>
        )}
      </ul>

    </div>
  );
};

export default SolvedProblems;
