import React from 'react';
import './Footer.css'; // CSS 파일을 import (아직 생성하지 않았다면 생성해야 합니다)

function Footer() {
  return (
    <footer className="footer">
      <div className="frame-3325">
        <div className="title">
          <div className="frame-3324">
            <img className="logo" src="img/logo-2.svg" alt="Logo" />
            <img className="logo-1" src="img/logo-7.svg" alt="Logo" />
          </div>
        </div>
        <div className="text-link-list s1normal">
          <div className="text-8 valign-text-middle">기업 소개</div>
          <div className="text-8 valign-text-middle">비즈니스 문의</div>
          <div className="text-8 valign-text-middle">고객 센터</div>
          <div className="text-8 valign-text-middle">이용 약관</div>
          <div className="text-8 valign-text-middle">블로그</div>
          <div className="text-8 valign-text-middle">개인정보 처리방침</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;