import { Routes, Route, Navigate } from 'react-router-dom';
import UserProfile from './UserProfile';
import Options from './Options';
import SolvedProblems from './SolvedProblems';
import FeedbackReports from './FeedbackReports';
import Settings from './Settings';
import "./MyPage.css";

function MyPage() {
  return (
    <div className='my-page'>
      <div className="my-page-main-layout">
        <div className="my-page-left-layout">
            <UserProfile />
            <Options />
        </div>
        <Routes>
          <Route path="solved-problems" element={<SolvedProblems />} />
          <Route path="feedback-report" element={<FeedbackReports />} />
          <Route path="settings" element={<Settings />} />
          <Route index element={<Navigate to="/mypage/solved-problems" />} /> {/* 기본 페이지 설정 */}
        </Routes>
      </div>
    </div>
  );
}

export default MyPage;