import "./SolvingHeader.css";
import { Link, useLocation } from 'react-router-dom';

function SolvingHeader() {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const getClassName = (path) => {
    return location.pathname === path ? 'clicked' : 'text-4';
  };

  return (
    <header className="solving-header">
      <div className="solving-frame-3322">
        <div className="frame-3321">
          <Link to="/"><img className="solving-logo" src="/img/logo-6.svg" alt="Logo" /></Link>
          <Link to="/problem/list"><div className="frame-3320"><div className="text-161 s2normal">문제 목록</div></div></Link>
        </div>
        <div className="frame-3319">
          {isLoggedIn ? (
            <Link to="/mypage"><div className="avatar"></div></Link>
          ) : (
            <Link to="/login"
              state={{ from: location.pathname }}>
              <div className="button-3 button s2normal">로그인 / 회원가입</div>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}

export default SolvingHeader;